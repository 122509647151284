.converter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Aptos', sans-serif;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.dropzone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.dropzone {
    border: 4px dashed #00a938;
    font-weight: bold;
    padding: 50px;
    text-align: center;
    align-content: center;
    width: 100%;
    max-width: 700px;
    height: 200px;
    box-sizing: border-box;
    margin-bottom: 20px;
    cursor: pointer;
    color: #00a938;
    transition: font-size 0.3s ease;
}

.dropzone.file-loaded p {
    font-size: 1.5rem;
}

.icon-spacing {
    margin-right: 10px;
}

.message-container {
    min-height: 200px;
}

.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.button-converter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    background-color: transparent;
    border: 2px solid;
    border-radius: 5px;
    font-weight: bold;
    font-size: medium;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 300px;
}

.button-converter:hover {
    opacity: 0.8;
}

.button-converter:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.dropzone-button {
    background-color: #00a938;
    border-color: #00a938;
}

.number-label {
    width: 100px;
    font-size: large;
}

.number-input-container {
    padding-top: 10px;
}

.input-number {
    width: 185px;
    font-size: x-large;
    margin-left: 15px;
    border-radius: 5px;
    text-align: center;
}

.input-error {
    border: 2px solid;
    border-radius: 5px;
    border-color: red;
}

.larger-button {
    background-color: #4379F2;
    border-color: #4379F2;
    padding: 15px 20px;
}

.smaller-button {
    background-color: orange;
    border-color: orange;
    padding: 10px 15px;
    font-size: 0.9em;
}

.message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    font-size: medium;
}

.icon-message {
    margin-top: 10px;
}

.message.success-elaborazione {
    color: #00a938;
}

.message.success-api {
    color: #4379F2;
}

.message.error {
    color: red;
}