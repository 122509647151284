.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Aptos', sans-serif;
}

.profile-form {
    padding-top: 5%;
    transform: scale(1.25);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-form label {
    font-weight: bold;
    color: #00a938;
    margin: 5px 0;
}

.profile-form input {
    padding: 10px;
    margin: 5px 0;
    width: 250px;
}