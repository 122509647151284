.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Aptos', sans-serif;
    min-height: 100vh;
}

.login-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 80px;
}

.login-logo {
    height: 150px;
}

.login-form-container {
    padding-top: 5%;
    transform: scale(1.25);
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form label {
    font-weight: bold;
    color: #00a938;
    margin: 5px 0;
}

.login-form input {
    padding: 10px;
    margin: 5px 0;
    width: 250px;
}

.password-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.toggle-password-button {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}

.submit-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #00a938;
    border-color: #00a938;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 2px solid;
    border-radius: 5px;
    font-weight: bold;
}

.submit-button:hover {
    opacity: 0.8;
}

.error-message {
    font-size: x-small;
    color: red;
    text-align: center;
}

.login-footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
    font-style: italic;
    font-size: smaller;
    color: #999;
}

.olicom-logo {
    height: 40px;
    margin-left: 10px;
}