.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00a938;
}

.header-logo {
    margin-left: 10px;
    height: 65px;
}

.header-actions {
    display: flex;
    align-items: center;
}

.header-logout {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
}

.no-margin {
    margin: 0%;
}